<template>
  <div class="mt-4">
    <button @click="$router.go(-1)" class="btn btn-default btn-sm">Back</button>
    <div class="mt-2 card shadow w-100 p-4">
      <div class="row">
        <div class="col-md-7">
          <text-input
            :value="name.value"
            :errors="errors.name"
            :label="name.label"
            :placeholder="name.placeholder"
            @type="(v) => (name.value = v)"
            :is_password="false"
            :required="true"
          ></text-input>
        </div>
      
        <div class="col-md-7 mt-3">
          <number-input-option-append
            :selected="selected_currency"
            :options="currencies"
            :value="price.value"
            :errors="errors.price"
            :label="price.label"
            :placeholder="price.placeholder"
            @type="(v) => (price.value = v)"
            @change-select="(v)=>(selected_currency = v)"
            :required="false"
          ></number-input-option-append>
        </div>
        <div class="col-md-7">
          <number-input
            :value="discount.value"
            :errors="errors.discount"
            :label="discount.label"
            :placeholder="discount.placeholder"
            @type="(v) => (discount.value = v)"
            :is_password="false"
            :required="false"
          ></number-input>
        </div>
        <div class="col-md-7">
          <text-input
            :value="ribbon.value"
            :errors="errors.ribbon"
            :label="ribbon.label"
            :placeholder="ribbon.placeholder"
            @type="(v) => (ribbon.value = v)"
            :is_password="false"
            :required="false"
          ></text-input>
        </div>
        <div class="col-md-7" >
          <div class="form-group">
            <label class="form-control-label"
              >What Categories does your product belong to?</label
            >
             <category-multiselect
               :categoriesList="categoriesList"
               @selected="getSelectedCategories"
               :oldSelected="oldCategories"
             />

            <label
              v-for="error in errors.category"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
        </div>
        <div class="col-md-7">
          <label class="form-control-label">Description:</label>
          <rich-text
            id="productdesc"
            @change="(v) => (description.value = v)"
            :text="description.initial_value"
          ></rich-text>
           <label
              v-for="error in errors.description"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
        </div>
        <div class="col-md-7" v-if="$route.name === 'Update Product'">
          <label class="form-control-label">Publish</label>
          <div class="col-md-4">
            <label class="custom-toggle text-white">
              <input
                id="change_visibility"
                type="checkbox"
                v-model="post_data.visibilityStatus"
              />
              <span
                class="custom-toggle-slider rounded-circle bg-default"
                data-label-off="No"
                data-label-on="Yes"
              ></span>
            </label>
          </div>
        </div>
        <button
          :disabled="loading"
          @click="submit"
          class="btn btn-block col-md-7 mt-3 btn-default"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "../../components/TextInput";
import NumberInput from "../../components/NumberInput";
import RichTextEditor from "../../components/RichTextEditor";
import axios from "axios";
import { alert } from "../../utils/alertUtils";
import NumberInputOptionAppendVue from '../../components/NumberInputOptionAppend.vue';
import CategoryMultiSelect from "../../components/CategoryMultiSelect.vue";
export default {
  name: "New Product",
  components: {
    "text-input": TextInput,
    "number-input": NumberInput,
    "rich-text": RichTextEditor,
    'number-input-option-append': NumberInputOptionAppendVue,
    'category-multiselect': CategoryMultiSelect
},
  data() {
    return {
      oldCategories:[],
      name: {
        value: "",
        errors: [],
        label: "Product Name:",
        placeholder: "Enter the name of the product",
      },
      discount: {
        value: 0,
        errors: [],
        label: "Discount(%):",
        placeholder: "Enter the discount in % 1-100)",
      },
      ribbon: {
        value: "",
        errors: [],
        label: "Ribbon:",
        placeholder: "Enter the ribbon (eg. new, deal, ...)",
      },
      description: {
        value: "",
        initial_value: "",
      },
      price: {
        value: 0,
        errors: [],
        label: "Price:",
        placeholder: "Enter the price",
      },
      currencies:['RWF','$'],
      selected_currency:'RWF',
      categories: [],
      post_data: {
       
      },
      errors: {},
      product: {},
      user_profile: this.$store.getters.getUserProfile,
      loading: false,
    };
  },
  computed:{
    categoriesList:function(){
      return this.categories
    }
  },
  methods: {
    submit: function () {
      this.errors = {};
      this.loading = true
      let post_data = this.post_data;
      post_data.name = this.name.value;
      post_data.ribbon = this.ribbon.value;
      post_data.description = this.description.value;
      post_data.discount = this.discount.value;
      post_data.currency= this.selected_currency;
      post_data.price = this.price.value;
      post_data.entity = this.user_profile.entity.id;
      post_data.parent_category=parseInt(this.post_data.parent_category)
      let url = `${process.env.VUE_APP_BACKEND_URL}/api/products`;
      let method = axios.post;
      if (this.$route.name === "Update Product") {
        url = `${process.env.VUE_APP_BACKEND_URL}/api/products/${this.$route.params.uuid}`;
        method = axios.put;
      }
      method(url, this.post_data, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      })
        .then((res) => {
          this.loading = false;
          if (this.$route.name === "Update Product") {
            alert.success_center("Update successful");
            this.$router.push({ name: "Products" });
          } else {
            this.$router.push({
              name: "Product Gallery",
              params: { uuid: res.data.uuid },
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.status === 400) {
              this.errors = err.response.data;
            }
            if(err.response.status===403 || err.response.status===401){
               alert.error("Session Expired");
               this.$store.dispatch("logout");
            }
          }
        });
    },
    getSelectedCategories:function(evt){
      this.post_data.categories=evt
    },
    fetch_categories: function () {
     axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/parentCategories`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
          )
        .then((res) => {
           this.categories=res.data
           this.post_data.parent_category=this.categories[0]
        })
        .catch((err) => {
           if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired");
              this.$store.dispatch("logout");
            }
        });
    },
    fetch_product: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/products/${this.$route.params.uuid}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((res) => {
          this.product = res.data;
          this.populate_existing_data(res.data);
         
        })
        .catch((err) => {
           if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired");
               this.$store.dispatch("logout");
            }
        });
    },
    populate_existing_data: function (product) {
      this.name.value = product.name;
      this.ribbon.value = product.ribbon;
      this.description.initial_value = product.description;
      this.price.value = product.price;
      // this.post_data.category = product.category;
      this.discount.value = product.discount;
      this.post_data.visibilityStatus = product.visibilityStatus;
      this.loading = false;
      this.oldCategories=product.categories
    },
  },
  created() {
    if (this.$route.name === "Update Product") {
      this.fetch_product();
    }
    this.fetch_categories();
  },
};
</script>

<style>
</style>