<template>
    <div class="form-group">
      <label v-if="label" class="form-control-label"
        >{{ label }} <span v-if="required" class="text-danger">*</span></label
      >
      <div class="input-group">
        <input
            class="form-control"
            :placeholder="placeholder"
            :value="value"
            type="number"
            @keyup.prevent="handle_input"
        />
        <div class="input-group-append">
            <select :selected="selected" :value="selected" @change="handle_change" class="form-control">
                 <option v-for="item in options" :key="item" :value="item">{{item}}</option>
            </select>
        </div>
      </div>
     
     
      <label
        v-for="error in errors"
        :key="error"
        class="form-control-flush text-danger text-sm"
        >{{ error }}</label
      >
    </div>
  </template>
  
  <script>
  export default {
    name: "NumberInputOptionAppend",
    props: {
      options:Array,  
      selected:{
        type:String,
        default: "RWF",
      },
      value: {
        type: [String, Number],
        default: "",
      },
      errors: Array,
      label: [String, Number],
      placeholder: {
        type: String,
        default: "",
      },
      required: {
        default: false,
        type: Boolean,
      },
    },
    methods: {
      handle_input: function (e) {
        this.$emit("type", e.target.value);
      },
      handle_change:function(e){
        this.$emit("change-select",e.target.value)
      }
    },
  };
  </script>
  
  <style>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  </style>